@import '~antd/dist/antd.css';

:root {
  --primary: #F13223;
  --secondary: #ff1100;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary);
}

.ant-tabs-ink-bar {
  background-color: var(--primary);
}

.sys-flex-column {
  flex-direction: column;
}

.sys-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sys-title {
  text-transform: uppercase;
  color: rgba(61, 61, 61, 0.452);
  text-align: center;
}

.sys-card-operation {
  width: 100%;
  border-radius: 8px;
}

.sys-card-operation .ant-form-item {
  margin-bottom: 0px !important;
}

.sys-card-operation-title {
  font-weight: 700;
}

.sys-btn-small {
  height: 25px;
  padding-top: 1px !important;
  font-size: 13px;
}

video {
  width: 100% !important;
  border-radius: 8px;
}

.drawingBuffer {
  display: none;
}

.sys-btn-circle {
  border-radius: 100% !important;
}
.ant-modal-header {
  border-radius: 8px 8px 0px 0px;
}
.ant-modal-content {
  border-radius: 8px;
}

.sys-modal-confirm .ant-btn.ant-btn-default,
.sys-btn-default {
  background-color: #FFF;
  border-color: var(--primary);
  color: var(--primary);
  border-radius: 8px;
}

.sys-modal-confirm .ant-btn.ant-btn-default:hover,
.sys-modal-confirm .ant-btn.ant-btn-default:focus,
.sys-btn-default:hover,
.sys-btn-default:focus {
  color: var(--secondary);
  border-color: var(--secondary);
}

.sys-modal-confirm .ant-btn.ant-btn-primary,
.ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary,
.sys-btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 8px;
}

.sys-btn-danger {
  background-color: #F13223;
  border-color: #F13223;
}

.sys-btn-danger:focus, .sys-btn-danger:hover {
  background-color: #cf3429;
  border-color: #cf3429;
}

.ant-dropdown-menu-item-selected {
  color: var(--primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}

.sys-modal-confirm .ant-btn.ant-btn-primary:hover, .sys-modal-confirm .ant-btn.ant-btn-primary:focus,
.ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary:hover, .ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary:focus,
.sys-btn-primary:hover, .sys-btn-primary:focus {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.ant-table-filter-dropdown-btns .ant-btn.ant-btn-link, .ant-table-filter-dropdown-btns .ant-btn.ant-btn-link {
  color: var(--secondary);
}

.ant-table-filter-dropdown-btns .ant-btn.ant-btn-link:hover, .ant-table-filter-dropdown-btns .ant-btn.ant-btn-link:focus,
.sys-btn-text:hover, .sys-btn-text:focus {
  color: var(--primary);
}

.sys-btn-add {
  width: 25px !important;
  height: 25px !important;
  border-radius: 100% !important;
  padding: 2px !important;
}

.sys-content {
  height: 100vh;
  overflow-y: hidden;
}

.sys-container {
  padding: 10px 10px 10px 15px;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 100px;
}

.sys-navbar {
  background-color: #FFF;
  height: 50px;
  box-shadow: 1px 1px 8px #0000004d;
  /* position: fixed !important; */
  width: 100%;
  z-index: 9;
}

.sys-navbar-row {
  padding: 10px;
  align-items: center;
}

.sys-sidebar {
  background-color: #FFF;
  box-shadow: 1px 1px 8px #0000004d;
  z-index: 10;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sys-navbar-row-logo {
  width: 55px;
}

.sys-navbar-row-col-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sys-navbar-avatar {
  width: 30px;
  border-radius: 50%;
}

.sys-navbar-avatar-perfil {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.sys-navbar-avatar:hover {
  cursor: pointer;
}

.fab-button {
  position: fixed;
  left: 0px;
  bottom: 0px;
  opacity: 0.3;
  z-index: 11;
}

.fab-button:hover {
  opacity: 1;
  z-index: 11;
}

.fab-button-right {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0.3;
  z-index: 11;
}

.fab-button-right:hover {
  opacity: 1;
  z-index: 11;
}

.fab-button-br, .fab-button-en {
  transition: 0.5s;
  position: absolute;
  bottom: 2px;
  z-index: 11;
}

.fab-button-br {
  left: 40px;
}
.fab-button-en {
  left: 75px;
}

.fab-button-br.hidden, .fab-button-en.hidden {
  opacity: 0;
  left: 10;
}
.fab-button-br.show, .fab-button-en.show {
  opacity: 0.3;
}

.fab-button-br.show:hover, .fab-button-en.show:hover {
  opacity: 1;
}

.sys-bandeira {
  width: 30px;
  height: 18px;
  z-index: 11;
}

.sys_sidebar__col_button {
  color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.2s !important;
}

.sys_sidebar__col_button:hover {
  color: var(--primary);
}

.sys_sidebar__col_button.active {
  color: #000 !important;
}

.sys_sidebar__col_button.active::after {
  content: '●';
  color: var(--primary);
  margin-left: 15px !important;
}

.sys_sidebar__col_button svg {
  margin-right: 10px;
}

.sys_sidebar__col_button.active svg {
  color: var(--primary);
}

a {
  color: #000 !important;
}

.sys-breadcrumb a {
  color: rgba(0, 0, 0, 0.486) !important;
}

a:hover, a:focus {
  color: var(--primary) !important;
}

.float-right {
  float: right;
}

.table__row_table {
  background-color: #FFF;
  padding: 15px;
  border-radius: 6px;
}

.table__form_filter {
  display: flex;
  justify-content: flex-end;
}

.table__form_filter .ant-form-item {
  margin-bottom: 0px !important;
}

.table__title {
  height: 50%;
  display: flex;
  align-items: flex-end;
  color: var(--primary);
  font-size: 20px;
}

.table__summary {
  height: 50%;
  display: flex;
  align-items: flex-start;
}

.table__summary_qtd {
  color: var(--link);
}

.table__row_buttons_addons {
  padding: 15px 0px;
}

.table__row_buttons {
  padding: 15px 0px;
  justify-content: flex-end;
}

.table__row_button {
  border-radius: 15px;
}

.table__row_button + .table__row_button {
  margin-left: 10px;
}

.table__row_button_refresh {
  border-radius: 15px;
  width: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__row_button_refresh + .table__row_button {
  margin-left: 10px;
}

.table__ table th  {
  text-align: center !important;
}

.table__actions {
  display: flex !important;
  justify-content: center !important;
}

.sys-form {
  background-color: #FFF;
  padding: 15px 25px;
  border-radius: 8px;
  box-shadow: 1px 1px 8px #0000004d;
}

.sys-upload .ant-upload.ant-upload-select {
  width: 100%;
}

.sys-ant-upload .ant-upload {
  width: 100%;
}

.ant-divider-horizontal {
  margin: 8px 0px;;
}

.table__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.table__btn_view {
  background-color: rgb(36, 97, 230);
}

.table__btn_view:hover, .table__btn_view:focus {
  background-color: rgba(36, 98, 230, 0.699);
}

.table__btn_edit {
  background-color: rgb(230, 162, 36);
}

.table__btn_edit:hover, .table__btn_edit:focus {
  background-color: rgba(230, 162, 36, 0.699);
}

.table__btn_delete {
  background-color: rgb(212, 45, 23);
}

.table__btn_delete:hover, .table__btn_delete:focus {
  background-color: rgba(212, 45, 23, 0.699);
}

.table__btn_recover {
  background-color: rgb(29, 212, 23);
}

.table__btn_recover:hover, .table__btn_recover:focus {
  background-color: rgba(29, 212, 23, 0.699);
}

.table__btn_action_1 {
  background-color: rgb(255, 121, 43);
}

.table__btn_action_1:hover, .table__btn_action_1:focus {
  background-color: rgba(255, 121, 43, 0.699);
}

.sys-label-txt {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.sys-input-switch {
  position: absolute;
  top: 25px;
  right: 5px;
}

.ant-switch-checked {
  background-color: var(--primary);
}

.sys-card {
  background-color: #e7e7e736 !important;
  border-radius: 8px !important;
}

@media (min-width: 500px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(0,0,0,0.0) !important;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background-color: rgba(0,0,0,0.2);
  }
}
